const DASHBOARD = 'DASHBOARD';
const OFFERS = 'OFFERS';
const CARDS = 'CARDS';
const RESOURCES = 'RESOURCES';
const WITHDRAWALS = 'WITHDRAWALS';

const NORMAL = 'NORMAL';
const FAIL = 'FAIL';
const LOADING = 'LOADING';

const WHITE = 'WHITE';
const BLACK = 'BLACK';
const YELLOW = '#dbcd00';

const ACCEPT = 'ACCEPT';
const REFUSE = 'REFUSE';

const HEADERS = {
  'Content-Type': 'application/json'
};

export {
  DASHBOARD,
  OFFERS,
  CARDS,
  RESOURCES,
  WITHDRAWALS,
  NORMAL,
  FAIL,
  LOADING,
  WHITE,
  BLACK,
  HEADERS,
  ACCEPT,
  REFUSE,
  YELLOW
};