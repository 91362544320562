/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from 'react';
import Header from '../../Component/Header';
import { loginByEmailAndPassword, updateLogin } from '../../Redux/Slices/loginSlice';
import { useDispatch, useSelector } from 'react-redux';
import { BLACK, NORMAL } from '../../Redux/values';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { setTokenInside } from '../../Redux/Slices/adminDashboardSlice';
import Spinner from './utils/Spinner';

function Login() {

  const { loginData } = useSelector(state => state);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    verify();
  }, []);

  const verify = () => {
    const cookie = Cookies.get('token');
    const uid = Cookies.get('uid');

    if (cookie && jwtDecode(cookie).sub === uid) {
      dispatch(setTokenInside(cookie));
      navigate('/admin/dashboard');
      return;
    }
  }


  const submit = async (e) => {
    e.preventDefault();
    const result = await loginByEmailAndPassword(dispatch, {
      email: loginData.email,
      password: loginData.password
    });

    if (result) {
      verify();
    }
  }

  return (
    <div>
      <Header/>
      <div style={{paddingTop: '150px'}}>
        <form className='border' style={{borderColor: loginData.loginBorder === NORMAL ? 'var(--grey)': 'var(--red)'}} onSubmit={submit}>
          <h2 style={{paddingBottom: '10px'}}>Login</h2>
          <input onChange={(event) => dispatch(updateLogin({...loginData, email: event.target.value}))} type='email' className='inputs' placeholder='Enter email'/>
          <input onChange={(event) => dispatch(updateLogin({...loginData, password: event.target.value}))} type='password' className='inputs' placeholder='Enter password'/>
          <button type='submit' id='login-button' style={{width: '320px', display:  'flex', justifyContent: 'center', alignItems: 'center'}} className='inputs'>
            {loginData.loginButton === NORMAL ? 'Connect':
              <Spinner color={BLACK}/>
            }
          </button>
        </form>
      </div>
    </div>
  )
}

export default Login;