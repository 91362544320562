import { createSlice } from "@reduxjs/toolkit";
import { CARDS, DASHBOARD, OFFERS, RESOURCES, WITHDRAWALS } from "../values";

const selectedListSlice = createSlice({
  name: 'selected',
  initialState: {
    currentSelected: null
  },
  reducers: {
    changeRoot: (state, page) => {
      state.currentSelected = page.payload;
    },
    onLoadPage: (state, uri) => {
      const pages = uri.payload.split('/');
      const page = pages[pages.length-1];
      
      switch (page.toUpperCase()) {
        case DASHBOARD:
          state.currentSelected = DASHBOARD;
          break;
        case CARDS:
          state.currentSelected = CARDS;
          break;
        case OFFERS:
          state.currentSelected = OFFERS
          break
        case RESOURCES:
          state.currentSelected = RESOURCES;
          break;
        case WITHDRAWALS:
          state.currentSelected = WITHDRAWALS;
          break;
        default: state.currentSelected = DASHBOARD;
      }
    }
  }
});

export const { changeRoot, onLoadPage } = selectedListSlice.actions;

export default selectedListSlice.reducer;