import React from 'react';
import Header from '../Component/Header';
import { Link } from 'react-router-dom';

function Terms() {
  return (
    <div>
      <Header/>
      <div className='s-body' style={{paddingBottom: '30px'}}>
        <div className='container'>
          <h1 style={{fontWeight: 700, lineHeight: '60px', margin: '0px 0px 16px'}}>Terms and Conditions</h1>
          <div className='space-left'>
            <p style={{margin: '0px 0px 16px'}}>Last Updated: December 18, 2023</p>
            <p style={{margin: '0px 0px 16px'}}>Welcome to Frozencash! Before using our mobile app, please read these Terms and Conditions carefully. By accessing or using Frozencash, you agree to comply with and be bound by these terms. If you do not agree with any part of these terms, please do not use the app.</p>
            <h2>Acceptance of Terms:</h2>
            <div className='space-left'>
              <p style={{margin: '0px 0px 16px', paddingBlock: '15px'}}>By using the Frozencash mobile app, you agree to be bound by these Terms and Conditions and all applicable laws and regulations. If you disagree with any part of these terms, you may not access the app.</p>
            </div>
            <h2>Use of the App:</h2>
            <div className='space-left'>
              <p style={{margin: '0px 0px 5px', paddingTop: '15px'}}>Frozencash grants you a non-transferable, non-exclusive, revocable license to use the app for your personal, non-commercial use.</p>
              <p>You may not reproduce, distribute, display, sell, lease, transmit, create derivative works from, translate, modify, reverse-engineer, disassemble, decompile or otherwise exploit the app.</p>
            </div>
            <h2 style={{marginTop: '20px'}}>User Accounts:</h2>
            <div className='space-left'>
              <p style={{margin: '0px 0px 5px', paddingTop: '15px'}}>To access certain features of the app, you may be required to create a user account. You are responsible for maintaining the confidentiality of your account information.</p>
              <p>You agree to provide accurate, current, and complete information during the registration process.</p>
            </div>
            <h2 style={{marginTop: '20px'}}>Prohibited Conduct:</h2>
            <div className='space-left'>
              <p style={{margin: '0px 0px 5px', paddingTop: '15px'}}>You may not use the app for any illegal or unauthorized purpose.</p>
              <p> You may not engage in any activity that disrupts, damages, or interferes with the app's functionality.</p>
            </div>
            <h2 style={{marginTop: '20px'}}>Termination:</h2>
            <div className='space-left'>
              <p style={{margin: '0px 0px 5px', paddingTop: '15px'}}>Frozencash reserves the right to terminate or suspend your account and access to the app at its sole discretion, without notice, for any reason.</p>
            </div>
            <h2 style={{marginTop: '20px'}}>Changes to Terms:</h2>
            <div className='space-left'>
              <p style={{margin: '0px 0px 5px', paddingTop: '15px'}}>Frozencash reserves the right to modify or replace these Terms and Conditions at any time. The updated terms will be posted on the app.</p>
            </div>
            <h2 style={{marginTop: '20px'}}>Contact Information:</h2>
            <div className='space-left'>
              <p style={{margin: '0px 0px 5px', paddingTop: '15px'}}>If you have any questions about these Terms and Conditions, please contact us at <Link to={'mailto:contact@frozencash.net'}>contact@frozencash.com</Link></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Terms;