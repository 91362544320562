import { configureStore } from "@reduxjs/toolkit";
import selectedListSlice from "./Slices/selectedListSlice";
import loginSlice from "./Slices/loginSlice";
import adminSlice from "./Slices/adminDashboardSlice";
import adminCardSlice from "./Slices/adminCardSlice";
import adminOffersSlice from "./Slices/adminOffersSlice";
import adminResourceSlice from "./Slices/adminResourceSlice";
import adminWithdrawalsSlice from "./Slices/adminWithdrawalsSlice";

const store = configureStore({
  reducer: {
    selectedList: selectedListSlice,
    loginData: loginSlice,
    admin: adminSlice,
    cards: adminCardSlice,
    offers: adminOffersSlice,
    resources: adminResourceSlice,
    payout: adminWithdrawalsSlice,
  }
});

export default store;