import { createSlice } from "@reduxjs/toolkit";
import { LOADING, NORMAL, HEADERS } from "../values";
import axios from "axios";
import { BTOKEN, GET_PAYOUTS, PAYOUT_ACTION } from "../APIs";
import Cookies from "js-cookie";

const adminWithdrawals = createSlice({
  name: 'withdrawals',
  initialState: {
    withdrawals: [],
    app_state: NORMAL,
    index: null,
    action: null,
    code: null
  },
  reducers: {
    changeAppState: (state, {payload}) => {
      state.app_state = payload;
    },
    setPayouts: (state, {payload}) => {
      state.withdrawals = payload;
    },
    setLoading: (state, {payload}) => {
      state.index = payload.index;
      state.action = payload.action;
    },
    setNull: (state, {payload}) => {
      state.index = null;
      state.action = null;
      state.code = null;
      state.withdrawals.splice(payload, 1);
    },
    setCode: (state, {payload}) => {
      state.code = payload;
    }
  }
});

export const payoutSearcher = async (dispatch) => {
  dispatch(adminWithdrawals.actions.changeAppState(LOADING));

  const response = await axios.get(GET_PAYOUTS, {
    headers: {...HEADERS, Authorization: BTOKEN + Cookies.get('token')}
  });

  const result = response.data;
  if (result.message === 'success') {
    dispatch(adminWithdrawals.actions.setPayouts(result.result))
    dispatch(adminWithdrawals.actions.changeAppState(NORMAL));
  }
}

export const sendActionServer = async (dispatch, index, id, action, code) => {
  dispatch(adminWithdrawals.actions.setLoading({index: index, action: action}));

  const response = await axios.post(PAYOUT_ACTION, {id: id, action: action, code: code}, {
    headers: {...HEADERS, Authorization: BTOKEN + Cookies.get('token')}
  });

  const result = response.data;
  if (result.message === 'success') {
    dispatch(adminWithdrawals.actions.setNull(index));
  }
  
}


export const { setCode } = adminWithdrawals.actions;

export default adminWithdrawals.reducer;