import React, { useEffect } from 'react';
import Header from '../../Component/Header';
import List from '../../Component/List';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from './utils/Spinner';
import { BLACK, NORMAL, WHITE, YELLOW } from '../../Redux/values';
import { changeValues, clearOffers, findOffers, toggleVisibility } from '../../Redux/Slices/adminOffersSlice';
import { addOfferServer } from '../../Redux/Slices/adminOffersSlice';

function Offers() {

  const { offers } = useSelector(state => state);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearOffers());
    findOffers(dispatch);
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeState = (content, index) => {
    toggleVisibility(dispatch, content.id, content.visibility, index);
  }

  const submit = (e) => {
    e.preventDefault();
    addOfferServer(dispatch, offers.to_add, offers.offers.length);
  }

  return (
    <div>
      <Header/>
      <div className='content-body' style={{paddingTop: '61px'}}>
        <div>
          <List />
          <div style={{paddingLeft: '255px'}}>
            <div style={{paddingBlock: '20px', width: '65%', margin: 'auto'}}>
              <div>
                <form className='form-grid' onSubmit={(e) => submit(e)}>
                  <select onChange={(e) => dispatch(changeValues({...offers.to_add, selected: e.target.value}))} style={{height: '35px', border: 'none', paddingInline: '10px', borderRadius: '5px', color: '#000'}}>
                    <option value={'o'}>Orange Card</option>
                    <option value={'m'}>Iam Card</option>
                    <option value={'i'}>Inwi Card</option>
                    <option value={'g'}>Free Fire Card</option>
                    <option value={'pg'}>Pubg Card</option>
                    <option value={'p'}>PayPal Card</option>
                    <option value={'pn'}>Payoneer Card</option>
                  </select>
                  <input placeholder='Short title' onChange={(e) => dispatch(changeValues({...offers.to_add, title: e.target.value}))} style={{border: 'none', paddingInline: '10px', borderRadius: '5px', color: '#000'}} type='text'/>
                  <input placeholder='Description' onChange={(e) => dispatch(changeValues({...offers.to_add, long_description: e.target.value}))} style={{border: 'none', paddingInline: '10px', borderRadius: '5px', color: '#000'}} type='text'/>
                  <input onChange={(e) => dispatch(changeValues({...offers.to_add, ices: e.target.value}))} placeholder='Require ices' style={{border: 'none', paddingInline: '10px', borderRadius: '5px', color: '#000'}} type='number'/>
                  <button type='submit' style={{border: 'none', fontWeight: 700, cursor: 'pointer', paddingInline: '10px', borderRadius: '5px', color: '#000'}}>
                    {
                      offers.buttonState === NORMAL ? 'Save': <Spinner color={BLACK}/>
                    }
                  </button>
                </form>
              </div>
              {
                offers.offers.length ?
                  offers.offers.map((content, index) =>
                    <div key={index} style={{background: '#141414', border: '1px solid var(--grey)', marginBottom: '10px', padding: '15px', borderRadius: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <div>
                      <p>{content.description}</p>
                    </div>
                    <div onClick={() => changeState(content, index)}>
                      {
                        content.visibility === null ?
                        <Spinner color={WHITE}/>
                        : <svg style={{fill: content.visibility === 1 ? 'green': YELLOW, cursor: 'pointer'}} xmlns="http://www.w3.org/2000/svg" height="16" width="18" viewBox="0 0 576 512"><path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"/></svg>
                      }
                    </div>
                  </div>
                  )
                  : <div style={{background: '#141414', border: '1px solid var(--grey)', marginBottom: '10px', padding: '15px', borderRadius: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Spinner color={WHITE}/>
                  </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Offers;