import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider
} from 'react-router-dom';

import './Styles/Css/App.css';
import Home from './View/Home';
import Terms from './View/Terms';
import PrivacyPolicy from './View/PrivacyPolicy';
import Login from './View/Admin/Login';
import Dashboard from './View/Admin/Dashboard';
import Offers from './View/Admin/Offers';
import { Provider } from 'react-redux';
import store from './Redux/store';
import Resources from './View/Admin/Resources';
import Withdrawals from './View/Admin/Withdrawals';
import PrivateRouters from './View/Admin/utils/PrivateRouters';
import DeleteAccount from './View/DeleteAccount';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path={'/'} element={<Home/>} />
      <Route path={'/terms-and-conditions'} element={<Terms/>} />
      <Route path={'/privacy-policy'} element={<PrivacyPolicy/>} />
      <Route path={'/admin'} element={<Login/>}/>
      <Route path={'/delete-account'} element={<DeleteAccount/>} />
      <Route element={<PrivateRouters/>}>
        <Route path={'/admin/dashboard'} element={<Dashboard/>}/>
        <Route path={'/admin/offers'} element={<Offers/>}/>
        <Route path={'/admin/resources'} element={<Resources/>}/>
        <Route path={'/admin/withdrawals'} element={<Withdrawals/>}/>
      </Route>
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);