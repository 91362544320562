import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BTOKEN, CARDS_URL, CARD_ACTION_URL } from "../APIs";
import { ACCEPT, HEADERS } from "../values";
import Cookies from "js-cookie";

const adminCard = createSlice({
  name: 'cards',
  initialState: {
    accepted: null,
    rejected: null,
    cards: null,
    accept_loading: null,
    refuse_loading: null,
    price: null,
    index: null
  },
  reducers: {
    setInfos: (state, data) => {
      state.accepted = data.payload.counting.accepted;
      state.rejected = data.payload.counting.rejected;
      state.cards = data.payload.cards
    },
    resetCard: (state) => {
      Object.keys(state).forEach((e) => state[e] = null);
    },
    setAction: (state, {payload}) => {
      switch (payload.type) {
        case ACCEPT:
          state.accept_loading = payload.index;
          break;
        default: state.refuse_loading = payload.index;
      }
    },
    removeCard: (state, {payload}) => {
      switch (payload.action) {
        case ACCEPT:
          state.accepted += 1;
          break;
        default: state.rejected += 1;
      }
      state.accept_loading = null;
      state.refuse_loading = null;
      state.cards.splice(payload.index, 1);
    },
    changePrice: (state, {payload}) => {
      state.price = payload.nb;
      state.index = payload.index;
    },
    clearInput: (state) => {
      state.price = null;
      state.index = null;
      console.log(state.index);
    }
  }
});

export const makeAction = async (dispatch, id, index, action, price, user_id) => {
  dispatch(setAction({index: index, type: action}));

  const response = await axios.post(CARD_ACTION_URL, {
    id: id,
    action: action,
    price: price,
    uid: user_id
  }, {
    headers: {...HEADERS, Authorization: BTOKEN + Cookies.get('token')}
  });

  const result = response.data;
  if (result.message === 'success') {
    dispatch(adminCard.actions.removeCard({index: index, action: action}));
  };
  dispatch(clearInput());
}

export const getCardsData = async (dispatch) => {
  const response = await axios.get(CARDS_URL, {
    headers: {...HEADERS, Authorization: BTOKEN + Cookies.get('token')}
  });

  const result = response.data;
  if (result.message === 'success') {
    dispatch(setInfos(result));
  }
}

export const { setInfos, resetCard, setAction, changePrice, clearInput } = adminCard.actions;

export default adminCard.reducer;