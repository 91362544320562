import React from 'react';
import icon from '../Assets/Images/icon.png';

function Header() {

  return (
    <header className='header-origin'>
      <div className='header'>
        <div className='header-child'>
          <div className='main-header'>
            <img className='logo' src={icon} alt={'icon-logo'}/>
            <h1 id={"fc"} style={{paddingLeft: '10px'}}>FrozenCash</h1>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header;