import React, { useEffect } from 'react';
import List from '../../Component/List';
import Header from '../../Component/Header';
import { LOADING, NORMAL, WHITE } from '../../Redux/values';
import Spinner from './utils/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import { editResourceFromServer, getResourcesFromServer, toLoading, changePrice } from '../../Redux/Slices/adminResourceSlice';

function Resources() {

  const { resources } = useSelector(state => state);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(toLoading());
    getResourcesFromServer(dispatch);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editResource = (id, index) => {
    editResourceFromServer(dispatch, id, index, resources.resources[index].default_);
  }

  return (
    <div>
      <Header/>
      <div className='content-body' style={{paddingTop: '61px'}}>
        <div>
          <List />
          <div style={{paddingLeft: '255px'}}>
            <div style={{paddingBlock: '20px', width: '65%', margin: 'auto'}}>
              {
                resources.load === NORMAL ?
                resources.resources.map((content, index) =>
                  <div key={index} style={{background: '#141414', border: '1px solid var(--grey)', marginBottom: '10px', padding: '15px', borderRadius: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <div>
                      <p>{content.name}</p>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      <input type='number' onChange={(e) => dispatch(changePrice({value: e.target.value, index: index}))} style={{border: 'none', borderRadius: '5px', height: '30px', paddingInline: '7px', color: '#000', marginRight: '10px'}} value={content.default_}/>
                      {
                        resources.edit === LOADING && resources.index === index ?
                        <Spinner color={WHITE}/>
                        : <svg onClick={() => editResource(content.id, index)} style={{fill: 'yellow', cursor: 'pointer'}} xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"/></svg>
                      }
                    </div>
                  </div>
                )
                 : <div style={{background: '#141414', border: '1px solid var(--grey)', marginBottom: '10px', padding: '15px', borderRadius: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <Spinner color={WHITE}/>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Resources;