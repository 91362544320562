import React, { useEffect } from 'react';
import Header from '../../Component/Header';
import List from '../../Component/List';
import { useDispatch, useSelector } from 'react-redux';
import { getDataFromServer, reset, searchBy, searchByEmail, searchReset, setTokenInside, toggleActiveServer } from '../../Redux/Slices/adminDashboardSlice';
import Spinner from './utils/Spinner';
import { WHITE } from '../../Redux/values';
import Cookies from 'js-cookie';

function Dashboard() {

  const { admin } = useSelector(state => state);
  const dispatch = useDispatch();
  
  useEffect(() => {

    dispatch(reset());
    dispatch(setTokenInside({token: Cookies.get("token"), uid: Cookies.get("uid")}));
    
    getDataFromServer(dispatch);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleActive = (index, content) => {
    toggleActiveServer(dispatch, content.id, index, content.active);
  }

  const changeText = (text) => {
    dispatch(searchBy(text));
  }

  const searchSubmit = (e) => {
    e.preventDefault();
    dispatch(searchReset());
    searchByEmail(dispatch, admin.search);

  }

  return (
    <div>
      <Header />
      <div className='content-body' style={{paddingTop: '61px'}}>
        <div>
          <List />
          <div style={{paddingLeft: '255px'}}>
            <div style={{paddingBlock: '20px', width: '65%', margin: 'auto'}}>
              <div className='cards-board grid-config-3' style={{paddingLeft: '0', paddingTop: '0'}}>
                <div className='card-board'>
                  <h2 style={{padding: '10px', textAlign: 'center', borderBottom: '1px solid var(--grey)'}}>Users Joined</h2>
                  <div className='counter-brd'>
                    <h3>{admin.users_counter ? admin.users_counter: <Spinner color={WHITE}/>}</h3>
                  </div>
                </div>
                <div className='card-board'>
                  <h2 style={{padding: '10px', textAlign: 'center', borderBottom: '1px solid var(--grey)'}}>Successfull Payouts</h2>
                  <div className='counter-brd'>
                    <h3>{admin.payouts !== null ? admin.payouts : <Spinner color={WHITE}/>}</h3>
                  </div>
                </div>
              </div>
              <div style={{paddingTop: '20px', textAlign: 'end'}}>
                <form onSubmit={(e) => searchSubmit(e)}>
                  <input type='text' onChange={(e) => changeText(e.target.value)} value={admin.search} placeholder='Search by email' style={{height: '40px', width: '300px', paddingInline: '10px', color: '#141414', borderRadius: '7px 0 0 7px', border: 'none'}}/>
                  <input type='submit' style={{height: '40px', cursor: 'pointer', border: 'none', paddingInline: '15px', color: '#141414', background: '#fff', borderLeft: '1px solid var(--grey)', fontWeight: 700, borderRadius: '0 7px 7px 0'}} value={'Search'}/>
                </form>
              </div>
              <div style={{paddingBlock: '20px'}}>
                {
                  admin.accounts.map((content, index) => 
                    <div key={index} style={{background: '#141414', border: '1px solid var(--grey)', marginBottom: '10px', padding: '15px', borderRadius: '10px', display: 'flex', justifyContent: admin.accounts[0] !== 1 ? 'space-between': 'center', alignItems: 'center'}}>
                      {
                        admin.users_counter ?
                          <>
                            <div>
                              <p>{content.email}</p>
                            </div>
                            {
                              content.active != null ?
                               <div onClick={() => toggleActive(index, content)}>
                                <svg style={{fill: content.active ? 'green': 'var(--red)', cursor: 'pointer'}} xmlns="http://www.w3.org/2000/svg" height="16" width="20" viewBox="0 0 640 512"><path d="M576 0c17.7 0 32 14.3 32 32V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V32c0-17.7 14.3-32 32-32zM448 96c17.7 0 32 14.3 32 32V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V128c0-17.7 14.3-32 32-32zM352 224V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V224c0-17.7 14.3-32 32-32s32 14.3 32 32zM192 288c17.7 0 32 14.3 32 32V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V320c0-17.7 14.3-32 32-32zM96 416v64c0 17.7-14.3 32-32 32s-32-14.3-32-32V416c0-17.7 14.3-32 32-32s32 14.3 32 32z"/></svg>
                              </div>: <Spinner color={WHITE} />
                            }
                          </>
                        : <Spinner color={WHITE}/>
                      }
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard;