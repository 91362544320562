import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { DASHBOARD, OFFERS, RESOURCES, WITHDRAWALS } from '../Redux/values';
import { changeRoot, onLoadPage } from '../Redux/Slices/selectedListSlice';
import Cookies from 'js-cookie';

function List() {

  const { selectedList } = useSelector(state => state);
  const dispatch = useDispatch();

  useEffect(() => {
    const page = window.location.pathname;
    dispatch(onLoadPage(page));
  }, [dispatch]);

  const changeTo = (page) => {
    dispatch(changeRoot(page));
  }

  const logout = () => {
    Cookies.remove("token");
    Cookies.remove("uid");
  }

  return (
    <div className='scroll-list'>
      <Link to={'/admin/dashboard'} onClick={() => changeTo(DASHBOARD)} className='list-items' id={selectedList.currentSelected === DASHBOARD ? 'selected':  null}>
        <svg style={{fill: 'white'}} xmlns="http://www.w3.org/2000/svg" height="22" width="22" viewBox="0 0 512 512"><path d="M64 64c0-17.7-14.3-32-32-32S0 46.3 0 64V400c0 44.2 35.8 80 80 80H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H80c-8.8 0-16-7.2-16-16V64zm406.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L320 210.7l-57.4-57.4c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L240 221.3l57.4 57.4c12.5 12.5 32.8 12.5 45.3 0l128-128z"/></svg>
        <p style={{paddingLeft: '10px'}}>Dashboard</p>
      </Link>
      <Link style={{fill: 'white'}} id={selectedList.currentSelected === OFFERS ? 'selected':  null} onClick={() => changeTo(OFFERS)} to={'/admin/offers'} className='list-items'>
        <svg xmlns="http://www.w3.org/2000/svg" height="22" width="22" viewBox="0 0 384 512"><path d="M64 0H242.7c17 0 33.3 6.7 45.3 18.7L365.3 96c12 12 18.7 28.3 18.7 45.3V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64C0 28.7 28.7 0 64 0zM96 192c-17.7 0-32 14.3-32 32v32h64V192H96zM64 352h80 96 80V288H240 144 64v64zM320 224c0-17.7-14.3-32-32-32H256v64h64V224zM160 192v64h64V192H160zM288 448c17.7 0 32-14.3 32-32V384H256v64h32zM160 384v64h64V384H160zM64 416c0 17.7 14.3 32 32 32h32V384H64v32z"/></svg>
        <p style={{paddingLeft: '10px'}}>Offers</p>
      </Link>
      <Link to={'/admin/resources'} className='list-items' id={selectedList.currentSelected === RESOURCES ? 'selected':  null} onClick={() => changeTo(RESOURCES)}>
        <svg style={{fill: 'white'}} xmlns="http://www.w3.org/2000/svg" height="22" width="20" viewBox="0 0 448 512"><path d="M50.7 58.5L0 160H208V32H93.7C75.5 32 58.9 42.3 50.7 58.5zM240 160H448L397.3 58.5C389.1 42.3 372.5 32 354.3 32H240V160zm208 32H0V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192z"/></svg>
        <p style={{paddingLeft: '10px'}}>Resources</p>
      </Link>
      <Link style={{fill: 'white'}} id={selectedList.currentSelected === WITHDRAWALS ? 'selected':  null} to={'/admin/withdrawals'} className='list-items' onClick={() => changeTo(WITHDRAWALS)}>
        <svg xmlns="http://www.w3.org/2000/svg" height="22" width="26" viewBox="0 0 640 512"><path d="M535 41c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l64 64c4.5 4.5 7 10.6 7 17s-2.5 12.5-7 17l-64 64c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l23-23L384 112c-13.3 0-24-10.7-24-24s10.7-24 24-24l174.1 0L535 41zM105 377l-23 23L256 400c13.3 0 24 10.7 24 24s-10.7 24-24 24L81.9 448l23 23c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0L7 441c-4.5-4.5-7-10.6-7-17s2.5-12.5 7-17l64-64c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9zM96 64H337.9c-3.7 7.2-5.9 15.3-5.9 24c0 28.7 23.3 52 52 52l117.4 0c-4 17 .6 35.5 13.8 48.8c20.3 20.3 53.2 20.3 73.5 0L608 169.5V384c0 35.3-28.7 64-64 64H302.1c3.7-7.2 5.9-15.3 5.9-24c0-28.7-23.3-52-52-52l-117.4 0c4-17-.6-35.5-13.8-48.8c-20.3-20.3-53.2-20.3-73.5 0L32 342.5V128c0-35.3 28.7-64 64-64zm64 64H96v64c35.3 0 64-28.7 64-64zM544 320c-35.3 0-64 28.7-64 64h64V320zM320 352a96 96 0 1 0 0-192 96 96 0 1 0 0 192z"/></svg>
        <p style={{paddingLeft: '10px'}}>Withdrawals</p>
      </Link>
      <div style={{position: 'absolute', bottom: '15px', width: '100%'}}>
        <Link onClick={logout} style={{fill: 'var(--red)', border: '1px solid var(--red)'}} to={'/admin'} className='list-items'>
        <svg xmlns="http://www.w3.org/2000/svg" height="22" width="22" viewBox="0 0 512 512"><path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"/></svg>
          <p style={{paddingLeft: '10px', color: 'var(--red)'}}>Log Out</p>
        </Link>
      </div>
    </div>
  )
}

export default List;