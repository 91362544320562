import React from 'react';
import Header from '../Component/Header';
import phone from '../Assets/Images/bg.png';
import gplay from '../Assets/Images/appstore.png';
import { Link } from 'react-router-dom';

function Home() {

  const toTheTop = () => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0
    });
  }

  return (
    <div>
      <Header/>
      <div className='body'>
        <div className='content'>
          <div className='content-text'>
            <h1 id='h1-title'>Welcome to FrozenCash App</h1>
            <div>
              <p className='text-description'>Welcome to the easiest application to earn money and many currencies...</p>
              <p className='text-description'>Just by completing offers... You can explore more by downloading the app</p>
            </div>
            <div className='imgs-items'>
              <a target='_blank' href='https://play.google.com/store/apps/details?id=com.modernapps.frozencash' rel="noreferrer" id='url'>
                <img src={gplay} id='google-play-icon' alt={'google-play'}/>
              </a>
            </div>
            <div className='terms-of-use'>
              <ul>
                <Link onClick={toTheTop} to={'/terms-and-conditions'}>
                  <li>Terms and conditions</li>
                </Link>
                <Link onClick={toTheTop} to={'/privacy-policy'}>
                  <li>Privacy policy</li>
                </Link>
                <Link to={'mailto:contact@frozencash.net'}>
                  <li>Contact us</li>
                </Link>
              </ul>
            </div>
          </div>
          <div className='content-element'>
            <img src={phone} className='screens' alt={'phones'}/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home;