import React from 'react';

function Spinner({color}) {
  return (
    <div>
      <svg width="16" height="16" stroke="#000" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g class="spinner_V8m1" style={{stroke: color}}><circle cx="12" cy="12" r="9.5" fill="none" stroke-width="3"></circle></g>
      </svg>
    </div>
  )
}

export default Spinner;