import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";
import { BTOKEN, DASHBOARD_URL, SEARCH, TOGGLE_ACTIVE_URL } from "../APIs";
import { HEADERS } from "../values";

const adminSlice = createSlice({
  name: 'admin',
  initialState: {
    token: null,
    uid: null,
    users_counter: null,
    payouts: null,
    accounts: [1],
    search: null
  },
  reducers: {
    setTokenInside: (state, {payload}) => {
      state.token = payload.token;
      state.uid = payload.uid;
    },
    setData: (state, data) => {
      state.accounts = data.payload.users;
      state.payouts = data.payload.table.payouts;
      state.users_counter = data.payload.table.users;
    },
    changeSpinnerStateById: (state, props) => {
      if (props.payload.to === null) {
        state.accounts[props.payload.index].active = props.payload.to;
        return;
      }
      state.accounts[props.payload.index].active = props.payload.to;
    },
    reset: (state) => {
      Object.keys(state).forEach((e) => state[e] = null);
      state.accounts = [1];
    },
    searchBy: (state, text) => {
      state.search = text.payload;
    },
    setAccounts: (state, accs) => {
      state.accounts = accs.payload;
    },
    searchReset: (state) => {
      state.accounts = [1];
    }
  }
});

export const getDataFromServer = async (dispatch) => { 

  const response = await axios.get(DASHBOARD_URL + Cookies.get('uid'), {
    headers: {...HEADERS, Authorization: BTOKEN + Cookies.get('token')}
  });

  dispatch(setData(response.data));
}

export const searchByEmail = async (dispatch, txt) => {

  const response = await axios.post(SEARCH, {text: txt}, {
    headers: {...HEADERS, Authorization: BTOKEN + Cookies.get('token')}
  });

  const result = response.data;
  if (result.message === 'success') {
    dispatch(setAccounts(result.result));
  }

}

export const toggleActiveServer = async (dispatch, id, index, status) => {

  dispatch(changeSpinnerStateById({index, status, to: null}));

  const data = {
    user: id
  }

  const response = await axios.post(TOGGLE_ACTIVE_URL, data, {
    headers: {...HEADERS, Authorization: BTOKEN + Cookies.get('token')}
  });

  const result = response.data;
  if (result.message === 'success') {
    dispatch(changeSpinnerStateById({index, status, to: status ? 0 : 1}));
  }

}

export const {
  setTokenInside,
  setData,
  changeSpinnerStateById,
  reset,
  searchBy,
  setAccounts,
  searchReset
} = adminSlice.actions;

export default adminSlice.reducer;