import React, { useEffect } from 'react';
import Header from '../../Component/Header';
import List from '../../Component/List';
import { useDispatch, useSelector } from 'react-redux';
import { ACCEPT, NORMAL, REFUSE, WHITE } from '../../Redux/values';
import Spinner from './utils/Spinner';
import { payoutSearcher, sendActionServer, setCode } from '../../Redux/Slices/adminWithdrawalsSlice';

function Withdrawals() {

  const { payout } = useSelector(state => state);
  const dispatch = useDispatch();

  useEffect(() => {
    payoutSearcher(dispatch);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const actionPayout = (index, id, action) => {
    sendActionServer(dispatch, index, id, action, payout.code);
  }

  return (
    <div>
      <Header/>
      <div className='content-body' style={{paddingTop: '61px'}}>
        <div>
          <List />
          <div style={{paddingLeft: '255px'}}>
            <div style={{paddingBlock: '20px', width: '65%', margin: 'auto'}}>
              {
                payout.app_state === NORMAL ?
                payout.withdrawals.map((content, index) =>
                  <div key={index} style={{background: '#141414', border: '1px solid var(--grey)', marginBottom: '10px', padding: '15px', borderRadius: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <div>
                      <p>{content.eemail} | {content.name} {content.email ? '• ' + content.email: null}</p>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      {
                        content.email ? null : <input onChange={(e) => dispatch(setCode(e.target.value))} type='number' placeholder='Send the Code...' style={{border: 'none', borderRadius: '5px', height: '30px', paddingInline: '7px', color: '#000', marginRight: '20px'}} value={payout.index === index ? payout.code: null}/>
                      }
                      {
                        payout.index === index && payout.action === ACCEPT ? <Spinner color={WHITE}/>
                        : <svg onClick={() => actionPayout(index, content.id, ACCEPT)} style={{fill: 'green', cursor: 'pointer'}} xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                      }
                      {
                        payout.index === index && payout.action === REFUSE ? <div style={{marginLeft: '11px'}}><Spinner color={WHITE}/></div>:
                        <svg onClick={() => actionPayout(index, content.id, REFUSE)} style={{fill: 'var(--red)', cursor: 'pointer', marginLeft: '15px'}} xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>
                      }
                    </div>
                  </div>
                )
                : <div style={{background: '#141414', border: '1px solid var(--grey)', marginBottom: '10px', padding: '15px', borderRadius: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Spinner color={WHITE}/>
                  </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Withdrawals;