import { createSlice } from "@reduxjs/toolkit";
import { FAIL, LOADING, NORMAL } from "../values";
import axios from "axios";
import { LOGIN } from "../APIs";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

const loginSlice = createSlice({
  name: 'login',
  initialState: {
    loginBorder: NORMAL,
    loginButton: NORMAL,
    token: null,
    email: null,
    password: null,
  },
  reducers: {
    changeState: (state) => {
      switch (state.loginButton) {
        case NORMAL:
          state.loginButton = LOADING;
          break;
        case LOADING:
          state.loginButton = NORMAL;
          break;
        default: break;
      }
    },
    updateLogin: (state, data) => {
      state.email = data.payload.email;
      state.password = data.payload.password;
    },
    setToken: (state, token) => {
      const tokenValue = token.payload;
      state.token = tokenValue;
      Cookies.set('token', tokenValue);
      Cookies.set('uid', jwtDecode(tokenValue).sub);
    },
    errorLogin: (state) => {
      state.loginBorder = FAIL;
    }
  }
});

export const loginByEmailAndPassword = async (dispatch, userData) => {
  dispatch(changeState());

  try {
    const response = await axios.post(LOGIN, {
      email: userData.email,
      password: userData.password
    });
  
    const data = response.data;
    if (data.message === 'success') {
      dispatch(setToken(data.token));
      dispatch(changeState());
      return true;
    } else {
      // eslint-disable-next-line no-throw-literal
      throw 0;
    }
  } catch (e) {
    dispatch(changeState());
    dispatch(errorLogin());
    return false;
  }
}

export const { changeState, updateLogin, setToken, errorLogin } = loginSlice.actions;

export default loginSlice.reducer;