import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { ADD_OFFER_URL, BTOKEN, OFFERS, TOGGLE_OFFERS } from '../APIs';
import { HEADERS, LOADING, NORMAL } from '../values';
import Cookies from 'js-cookie';

const adminOffers = createSlice({
  name: 'offer',
  initialState: {
    offers: [],
    loading: null,
    visibility: null,
    index: null,
    to_add: {
      selected: 'o',
      title: null,
      ices: 0,
    },
    buttonState: NORMAL
  },
  reducers: {
    setOffers: (state, {payload}) => {
      state.offers = payload;
    },
    clearOffers: (state) => {
      state.offers = [];
    },
    setLoading: (state, {payload}) => {
      state.offers[payload.index].visibility = null;
    },
    toggle: (state, {payload}) => {
      switch (payload.visibility) {
        case 1: state.offers[payload.index].visibility = 0;
          break;
        default: state.offers[payload.index].visibility = 1;
      }
    },
    changeValues: (state, {payload}) => {
      state.to_add.ices = payload.ices;
      state.to_add.selected = payload.selected;
      state.to_add.title = payload.title;
    },
    changeButtonState: (state) => {
      switch (state.buttonState) {
        case NORMAL:
          state.buttonState = LOADING;
          break;
        default: state.buttonState = NORMAL;
      }
    },
    addStaticOffer: (state, {payload}) => {
      state.offers.push({
        id: payload.id,
        description: payload.description,
        visibility: 1
      });
    }
  }
});

export const addOfferServer = async (dispatch, {ices, selected, title, long_description}, offers) => {
  dispatch(adminOffers.actions.changeButtonState());

  const response = await axios.post(ADD_OFFER_URL, {
    image: selected,
    description: title,
    l_description: long_description,
    price: ices
  }, {
    headers: {...HEADERS, Authorization: BTOKEN + Cookies.get('token')}
  });

  const result = response.data;
  if (result.message === 'success') {
    dispatch(adminOffers.actions.changeButtonState());
    dispatch(adminOffers.actions.addStaticOffer({id: offers + 1, description: title}));
  }

}

export const toggleVisibility = async (dispatch, id, visibility, index) => {

  dispatch(setLoading({index: index}));
  const response = await axios.post(TOGGLE_OFFERS, {id: id}, {
    headers: {...HEADERS, Authorization: BTOKEN + Cookies.get('token')}
  })

  const result = response.data;
  if (result.message === 'success') {
    dispatch(toggle({visibility: visibility, index}));
  }
}

export const findOffers = async (dispatch) => {
  const response = await axios.get(OFFERS, {
    headers: {...HEADERS, Authorization: BTOKEN + Cookies.get('token')}
  })

  const result = response.data;
  if (result.message === 'success') {
    dispatch(setOffers(result.offers));
  }
}

export const { setOffers, clearOffers, setLoading, toggle, changeValues } = adminOffers.actions;

export default adminOffers.reducer;