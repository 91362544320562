const baseURL = 'https://api.frozencash.net/api/admin';

//const baseURL = 'http://localhost:8000/api/admin';
const BTOKEN = 'Bearer ';

const LOGIN = baseURL + '/login';
const DASHBOARD_URL = baseURL + '/dashboard/';
const TOGGLE_ACTIVE_URL = baseURL + '/change-status';
const CARDS_URL = baseURL + '/cards';
const SEARCH = baseURL + '/search-user';
const CARD_ACTION_URL = baseURL + '/action';
const OFFERS = baseURL + '/get-offers';
const TOGGLE_OFFERS = baseURL + '/toggle-offer';
const ADD_OFFER_URL = baseURL + '/add-offer';
const GET_RESOURCES = baseURL + '/get-resources';
const EDIT_RESOURCES = baseURL + '/edit-resource';
const GET_PAYOUTS = baseURL + '/get-withdrawals';
const PAYOUT_ACTION = baseURL + '/withdrawals-action';

export {
  LOGIN,
  DASHBOARD_URL,
  TOGGLE_ACTIVE_URL,
  CARD_ACTION_URL,
  BTOKEN,
  CARDS_URL,
  SEARCH,
  OFFERS,
  TOGGLE_OFFERS,
  ADD_OFFER_URL,
  GET_RESOURCES,
  EDIT_RESOURCES,
  GET_PAYOUTS,
  PAYOUT_ACTION
};