import React from 'react';
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { Outlet, Navigate } from "react-router-dom";

function PrivateRouters() {
  return (
    <div>
      {
        Cookies.get('token') && jwtDecode(Cookies.get('token'))?.sub === Cookies.get('uid') ? <Outlet />: <Navigate to={'/admin'}/>
      }
    </div>
  )
}

export default PrivateRouters;