import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function DeleteAccount() {

  const history = useNavigate();

  useEffect(() => {
    
    document.getElementById('contact').click();
    history('/');

  })
  return (
    <a hidden href='mailto:contact@frozencash.net' id='contact'>Contact</a>
  );
}

export default DeleteAccount;