import { createSlice } from "@reduxjs/toolkit";
import { HEADERS, LOADING, NORMAL } from "../values";
import { BTOKEN, EDIT_RESOURCES, GET_RESOURCES } from "../APIs";
import axios from "axios";
import Cookies from "js-cookie";

const adminResource = createSlice({
  name: 'resource',
  initialState: {
    load: LOADING,
    resources: [],
    edit: NORMAL,
    index: null
  },
  reducers: {
    toNormal: (state) => {
      state.load = NORMAL;
    },
    toLoading: (state) => {
      state.load = LOADING;
    },
    setResources: (state, {payload}) => {
      for(let i=0; i < payload.length; i++) {
        const old = payload[i].name.replace(/_/g, " ").slice(1);
        payload[i].name = payload[i].name.substring(0, 1).toUpperCase() + old;
      }
      state.resources = payload;
    },
    editButtonToggle: (state, {payload}) => {
      switch (state.edit) {
        case NORMAL:
          state.edit = LOADING;
          state.index = payload;
          break;
        default: state.edit = NORMAL;
      }
    },
    changePrice: (state, {payload}) => {
      state.resources[payload.index].default_ = payload.value;
    }
  }
});

export const editResourceFromServer = async (dispatch, id, index, value) => {
  dispatch(adminResource.actions.editButtonToggle(index));

  const response = await axios.post(EDIT_RESOURCES, {id: id, price: value}, {
    headers: {...HEADERS, Authorization: BTOKEN + Cookies.get('token')}
  });

  const result = response.data;
  if (result.message === 'success') { 
    dispatch(adminResource.actions.editButtonToggle(index));
  }
}

export const getResourcesFromServer = async (dispatch) => {

  const response = await axios.get(GET_RESOURCES, {
    headers: {...HEADERS, Authorization: BTOKEN + Cookies.get('token')}
  });

  const result = response.data;
  if (result.message === 'success') {
    dispatch(adminResource.actions.toNormal());
    dispatch(adminResource.actions.setResources(result.data));
  }
}

export const { toLoading, changePrice } = adminResource.actions;
export default adminResource.reducer;